.home {
    padding: 40px 0;

    .left {
        width: 470px;
        background-color: #fff;
        padding: 30px 30px 40px 30px;
        border-radius: 20px;

        h2 {
            font-size: 54px;
            color: #000;
            line-height: 1.2;
            font-weight: 500;
            margin: 0 0 30px;
        }

        .invite {
            position: relative;
            margin: 0 0 30px;

            &:last-child {
                margin: 0;
            }

            &::after {
                content: "";
                position: absolute;
                background-color: #000;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0.7;
                z-index: 0;
                border-radius: 20px;
                cursor: pointer;
            }

            img {
                width: 100%;
                height: 250px;
                object-fit: cover;
                object-position: center;
                border-radius: 20px;

            }

            h4 {
                position: absolute;
                bottom: 25px;
                left: 25px;
                color: #fff;
                margin: 0;
                z-index: 22;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }

    .right {
        flex: 1;
        margin: 0 0 0 30px;

        .title {
            margin: 0 0 20px 0;

            h3 {
                font-size: 20px;
                margin: 0;
                font-weight: 600;
            }

            input {
                background-color: #F3F4F6;
                border: 1px solid #F3F4F6;
                padding: 12px 20px 12px 40px;
                border-radius: 10px;
                width: 100%;
                max-width: 400px;
                background-image: url(../../../public/assets/images/search.svg);
                background-repeat: no-repeat;
                background-size: 24px;
                background-position: 2% 50%;

                &:focus {
                    outline: none;
                    border-color: #045C46;
                }
            }
        }
    }
}

.developer-list {
    .d-flex {
        text-decoration: none;
        background: #fff;
        padding: 15px 20px;
        border-radius: 10px;
        background-image: url(../../../public/assets/images/right.svg);
        background-repeat: no-repeat;
        background-size: 8px;
        background-position: 97% 50%;
        margin: 0 0 10px;

        &:last-child {
            margin: 0;
        }

        .dev-pic {
            width: 80px;

            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .dev-name {
            flex: 1;

            h4 {
                font-size: 16px;
                font-weight: 600;
                color: #000;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                margin: 0;
                color: #000;
            }
        }
    }
}

.backlink {
    color: #000;
    font-size: 24px;
    text-decoration: none;
    background: #E4ECEB;
    min-width: 400px;
    border-radius: 10px;
    padding: 5px 35px 5px 5px;
    margin: 0 10px 0 0;
    a{
        span{
            b{
                word-break: break-all;
            }
        }
    }
    p{
        margin: 0;
    }
}

.developer-details {
    padding: 20px 0;

    .total-counter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 30px 0 0;
        background: #FFFFFF;
        padding: 10px;
        border-radius: 10px;


        .name {
            width: 58%;
            font-size: 20px;
            font-weight: 600;
            border-right: 1px solid #E3E3E3;
            display: flex;
            align-items: center;
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 50%;
                margin: 0 10px 0 0;
            }
            p{
                margin: 0px;
                font-weight: 400;
                font-size: 14px;
                
            }
        }

        .property {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
                background: #E4ECEB;
                padding: 10px;
                display: inline-block;
                border-radius: 10px;
                margin: 0 0 0 10px;
                min-width: 180px;

                p {
                    font-size: 10px;
                    margin: 0;
                }

                b {
                    font-size: 24px;
                }
            }
        }
    }

}

.all-property {
    margin: 20px 0 0;

    .title {
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 24px;
            margin: 0;
        }

        input {
            background: #F3F4F6;
            border: 1px solid #F3F4F6;
            padding: 10px 10px 10px 40px;
            border-radius: 10px;
            min-width: 440px;
            background-image: url(../../../public/assets/images/search.svg);
            background-repeat: no-repeat;
            background-size: 24px;
            background-position: 2% 50%;

            &:focus {
                outline: none;
                border-color: #045C46;
            }
        }

        select {
            word-wrap: normal;
            font-size: 18px;
            border: 0;
            background: transparent;
            text-transform: capitalize;

            &:focus {
                outline: none;
            }
        }
    }
}

.property-table {
    margin: 20px 0 0;
    background: #fff;
    border-radius: 20px;

    table {
        width: 100%;

        thead {
            tr {
                font-size: 14px;
                th {
                    background-color: #E4ECEB;
                    padding: 12px;

                    &:first-child {
                        border-radius: 10px 0px 0px 0px;
                    }

                    &:last-child {
                        border-radius: 0px 10px 0px 0px;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #EAEAEA;

                &:last-child {
                    border-bottom: 0;
                }

                td {
                    padding: 12px;
                    word-break: break-all;
                    font-size: 14px;
                }
            }
        }
    }
}

.alocated {
    background: #E4ECEB;
    display: inline-block;
    padding: 10px;
    border-radius: 2px;
    color: #2A9D4A;
    text-decoration: none;
    min-width: 150px;
}

.unalocated {
    background: #FFEDE0;
    display: inline-block;
    padding: 10px;
    border-radius: 2px;
    color: #DE7D38;
    text-decoration: none;
    min-width: 150px;
}

.all-property {
    .title {
        a {
            color: #00594c;
            text-decoration: none;
        }
    }

}

.deleteicon {
    cursor: pointer;
    margin: 4px;
    img{
        width: 24px;
    }
}

.all-list-show {
    select {
        border: 0;
        background: transparent;
        text-transform: capitalize;

        &:focus {
            outline: none;
        }
    }

    .primery-btn {
        background: #00594c;
        color: #fff;
    }
}

.responsive-table {
    // min-height: 200px;
    padding: 0 0 14px 0;
}

.not-fount {
    text-align: center;
    margin: 10px 0 0;
    font-size: 20px;
}

.adve-logo {
    width: 40px;
    border-radius: 50%;
    height: 40px;
    object-fit: cover;
}

.adv-rs {
    justify-content: space-between;

    .rasts {
        align-items: center;

        h3 {
            margin: 0;
            font-size: 18px;
        }
    }
}

.slide-pin {
    line-height: 0;
    margin: 0 0 0 10px;
}

.slide-pin input {
    -webkit-appearance: none;
    position: relative;
    width: 40px;
    height: 24px;
    background-color: #dbdbdb;
    background-size: cover;
    border-radius: 50px;
    outline: none;
    transition: background-image 0.9s;
}

.slide-pin input:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    transition: all 0.9s;
    background-color: #ffffff;
}

.slide-pin input:checked {
    background-color: #045C46;
    transition: background-image 0.9s;
}

.slide-pin input:checked:before {
    transform: translate(135%);
    transition: all 0.9s;
    background-color: #fff;
    top: 2px;
    left: -9px;
}


 @import 'bootstrap/dist/css/bootstrap.min.css';

 @import url(./assets/scss/onboard.scss);

 @import url(./assets/scss/header.scss);

 @import url(./assets/scss/home.scss);
 
 @import url(./assets/scss/modal.scss);

 @import url(./assets/scss/property.scss);

 @import url(./assets/scss/videolibrary.scss);


 @import "~video-react/styles/scss/video-react";
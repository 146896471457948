@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
    font-family: 'Poppins' !important;
    color: #29061A;
    font-size: 16px;
    line-height: 1.5;
    background-color: #FAFAFA !important;
}

h1,
h2,
h3,
h5,
h6 {
    color: #000;
}

.container {
    max-width: 1440px !important;


    @media (min-width: 1400px) {
        max-width: 1440px !important;
        padding: 0;
    }
}

.primery-btn {
    background: #045C46;
    border: 1px solid #045C46;
    color: #fff;
    padding: 12px;
    border-radius: 15px;
    text-decoration: none;
    transition: all .5s;
    display: block;
    text-align: center;
    width: 100%;

    &:hover {
        background: transparent;
        color: #045C46;
    }
}



.onboard {
    padding: 40px;
    justify-content: space-between;
    display: flex;

    .banner {
        width: 50%;

        img {
            width: 100%;
            border-radius: 40px;
            min-height: 880px;
            height: 880px;
            object-fit: cover;

            @media (min-width:2000px) {
                height: 100%;
            }
        }
    }

    .content {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 500px;
        margin: 0 auto;
        padding: 0 0 0 50px;

        h3 {
            background: #00594C;
            color: #fff;
            padding: 30px;
            border-radius: 50px;
            margin: 0 0 30px;
            font-size: 24px;
            font-weight: 600;
        }

        h2 {
            font-weight: 600;
            font-size: 34px;
            color: #000;
            margin: 0 0 40px;
        }

        p {
            width: 100%;
        }
    }
}


.form {
    width: 100%;

    .form-group {
        margin: 0 0 30px;
        position: relative;

        label {
            display: block;
            text-align: left;
            width: 100%;
            margin: 0 0 5px;
        }

        input,
        textarea,
        select {
            width: 100%;
            background: #F3F4F6;
            border: 1px solid #BEBEBE;
            padding: 10px;
            border-radius: 10px;
            resize: none;
            box-sizing: border-box;
            min-height: 46px;

            &:focus {
                outline: none;
                border-color: #045C46;
                background: #fff;
            }

            &::placeholder {
                color: #A1A1A1;
            }
        }

        input[type="password"] {
            padding-right: 41px;
        }

        .hide {
            position: absolute;
            right: 10px;
            bottom: 12px;
            cursor: pointer;
            top: 40px;
        }
    }
}
.offcanvas {
    padding: 30px 0;

    .offcanvas-header {
        padding: 20px 10px;
        background: #FAFAFA;

        h3 {
            font-size: 24px;
            margin: 0;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.pagination {
    margin: 20px 0px;
    justify-content: center;

    .page-item {
        margin: 5px;

        &:first-child {
            .page-link {
                background: #EFEFEF;
                font-size: 30px;
                font-weight: 600;
                border-radius: 10px;
            }
        }

        &:last-child {
            .page-link {
                background: #EFEFEF;
                font-size: 30px;
                font-weight: 600;
                border-radius: 10px;
            }

        }

        .page-link {
            background: transparent;
            border: 1px solid #CACACA;
            color: #000;
            border-radius: 10px;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
        }

        &.active {
            .page-link {
                background: #045C4633;
                border-color: #045C46;
            }
        }
    }

}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    /* Change the background color and transparency as needed */
    z-index: 9999;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-modal {
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-header {
        position: absolute;
        right: 20px;
        z-index: 9999;
        top: 20px;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 1;
        border: 0;
        border-radius: 10px;
        padding: 0;
        transition: all .5s;

        button.btn-close {
            opacity: 1;
            filter: invert(1);
            margin: 0;
            padding: 15px;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }

    .modal-body {
        height: 900px;
    }
}

.fullscreen-modal .modal-dialog {
    max-width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-modal .modal-content {
    background: transparent;
    border: none;
    // max-height: 100%;
    // overflow: hidden;
}

.fullscreen-modal .modal-footer {
    background: transparent;
    border: none;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}

.fullscreen-modal .modal-footer button {
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
}

.fullscreen-modal .modal-footer button:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.video-react-controls-enabled {
    height: 100% !important;
    padding: 0 !important;
}

.fullscreen-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;


    img,
    video {
        height: 100%;
        width: 100%;
        height: 900px;
        position: relative !important;
        object-fit: contain;
    }

}

.video-react .video-react-big-play-button {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
}

.delete-room-modal {
    text-align: center;

    .modal-content {
        min-height: 250px;
        max-width: 350px;
        margin: 0 auto;
    }

    .modal-header {
        flex-direction: column;
    }

    .modal-header,
    .modal-footer {
        justify-content: center;
        border: 0;
    }

    .btn-secondary {
        background-color: #000;
        border-color: #000;
    }

    .btn-primary {
        background-color: #045C46;
        border-color: #045C46;
    }
}

.popover-arrow {

    &::after,
    &::before {
        display: none !important;
    }
}

.admin {
    font-size: 18px;
    cursor: pointer;
    color: #29061A;
    font-weight: 600;
}

.text-danger {
    color: red !important;
}

.check_box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;
    padding-left: 30px;
    position: relative;
}

.check_box input[type=checkbox] {
    display: none;
    position: relative;
}

.check_box span.checkbox_check {
    border: 1px solid #BEBEBE;
    border-radius: 5px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 20px;
}

.check_box input:checked~.checkbox_check {
    background-color: #045C46;
    box-shadow: inset 0 0 0 1px #045C46;
    border-color: #045C46;
}

.checkbox_check:after {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    content: "";
    display: none;
    height: 9px;
    left: 7px;
    position: absolute;
    top: 3px;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    width: 4px;
}

.check_box input:checked~.checkbox_check:after {
    display: block;
}


.adv-banner {
    position: relative;
    width: 100%;
    height: 150px;
    img.w-100{
        height: 100%;
        object-fit: cover;
    }
    .upload_file{
        height: 100%;
        
    }
.uploaed{
    height: 120px;
}
        .cross-icon {
            position: absolute;
            top: -10px;
            right: -10px;
        }
}

.zip {
    gap: 10px;
    flex-wrap: wrap;

    span {
        background: #F3F4F6;
        font-size: 16px;
        padding: 8px;
        border-radius: 5px;
    }
}

.modalMap {
    input {
        width: 100%;
        margin: 0 0 10px;
        padding: 10px;
        border-radius: 10px;
        background: #F3F4F6;
        border: 0;
    }

    .modalView-map {
        height: 55vh;
        width: 100%;
    }
}

.footerModal {
    padding: 20px;
    padding-top: 0;
}

.btnc {
    background: #045C46;
    border: 1px solid #045C46;
    color: #fff;
    padding: 12px;
    border-radius: 15px;
    text-decoration: none;
    transition: all 0.5s;
    min-width: 150px;
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
}

.pac-container {
    z-index: 99999 !important;
}

.btn_minW {

    min-width: 230px;
    max-width: 230px;
    margin: auto;
}
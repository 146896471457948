.alocated-property {
    padding: 30px 0;

    .unalocated,
    .alocated {
        cursor: default;
    }
}

.owner-box {
    margin: 40px 0 0;

    .left {
        width: 400px;

        .propsImg {
            width: 100%;
            height: 340px;
            object-fit: cover;
            border-radius: 10px;
        }

        .alocated-to {
            background: rgba(243, 244, 246, 1);
            padding: 10px;
            border-radius: 10px;
            align-items: center;

            .owner {
                width: 80px;
                margin: 0 10px 0 0;

                img {
                    width: 75px;
                    height: 75px;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }

            .owner-name {
                flex: 1;

                h4 {
                    font-size: 24px;
                    font-weight: 600;
                }

                p {
                    margin: 0;
                    color: #787878;
                }
            }
        }

        .bg-set {
            background: #fff;
            padding: 15px;
            border-radius: 20px;
        }
    }

    .right {
        flex: 1;
        margin: 0 0 0 30px;
        padding: 30px;
        background: #fff;
        border-radius: 10px;
        position: relative;
    }
}

.more-info {
    margin: 20px 0 0;

    p {
        margin: 10px 0 0;
    }

    span {
        display: block;
        color: rgba(0, 0, 0, 0.5);
    }
}


.property-tab {
    padding: 0;
    border: 0 !important;

    li {
        border-right: 1px solid #ddd;

        &:first-child {

            .nav-link {
                border-radius: 10px 0 0 10px;

            }
        }

        &:last-child {
            border: 0;

            .nav-link {
                border-radius: 0 10px 10px 0;
                border: 0;
            }
        }


        .nav-link {
            width: 100%;
            border: 0;
            background: transparent;
            background: rgba(243, 243, 243, 1);
            padding: 8px 20px;
            border-right: 1px solid #ddd;
            min-width: 200px;
            border-radius: 0;
            color: #000000;

            &.active {
                background: rgba(228, 236, 235, 1);
                // border-color: rgba(228, 236, 235, 1);
            }



            &:focus {
                outline: none;
            }

            span {
                span {
                    color: #045C46;
                    font-size: 16px;
                    border: 1px solid #045C46;
                    display: inline-block;
                    border-radius: 50%;
                    padding: 2px;
                    min-width: 30px;
                    min-height: 30px;
                    margin: 0 0 0 10px;
                }
            }
        }
    }

}


.property_accordian {
    .accordion-item {
        border: 0;

        .accordion-body {
            margin: 0 0 10px;
            background: #F3F4F6;
            padding: 10px 20px;

            h4 {
                font-size: 14px;
                color: #a39a9a;
            }
        }

        .accordion-button {
            background: #F3F4F6;
            box-shadow: none;
            border-radius: 0;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &:after {
                transform: rotate(-90deg);
            }

            &:not(.collapsed) {

                &::after {
                    transform: rotate(-180deg);
                }
            }

            &.collapsed {
                margin: 0 0 10px;
                border-radius: 0;

                .edit {
                    img {
                        display: none;
                    }
                }
            }

            img {
                margin: 0 0 0 5px;
            }

            .d-flex {
                justify-content: space-between;
                width: 95%;
            }
        }
    }
}

.upload-title {
    justify-content: space-between;
    align-items: center;

    .backlink {
        a {
            display: inline-block;
            font-size: 20px;
            color: #000;
            text-decoration: none;
        }
    }

    .search-video {
        input {
            background-color: #F3F4F6;
            border-radius: 10px !important;
            padding: 10px;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .search-video {
        a {
            background: #00594C;
            color: #fff;
            padding: 15px;
            display: flex;
            align-items: center;
            font-size: 18px;
            border-radius: 20px;
            text-decoration: none;

            img {
                margin: 0 5px 0 0;
            }
        }
    }
}

.property-type {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);

    .box {
        padding: 10px;
        text-align: center;

        span {
            display: block;
            color: #fff;
        }

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

}

.appliance {
    span {
        font-size: 12px;
        color: #808080;
    }

    b {
        display: block;
    }
}


.fire-place {

    .plus {
        // background: #DE7D38;
        padding: 15px 20px;
        justify-content: space-between;
        align-items: center;

        span {
            text-align: center;

            &:first-child {
                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            b {
                font-size: 24px;
                color: #fff;
            }

            p {
                margin: 0;
                color: #D2D2D2;
                font-size: 14px;
                word-break: break-all;
                padding: 0 10px;
            }
        }
    }
}

.offcanvas {
    min-width: 500px;
}

.offcanvas-backdrop.show {
    opacity: 0.8;
}

.how-to {
    span {
        color: #808080;
        font-size: 14px;
        display: block;
        margin: 0 0 10px;
    }

    .d-flex {
        margin: 0 0 15px;
        align-items: center;
        border-bottom: 1px solid #E4E4E4;
        padding: 0 0 15px;
        position: relative;

        &::after {
            content: "";
            background-size: 30px;
            background-repeat: no-repeat;
            background-position: right;
            background-image: url(../../../public/assets/images/left-arrow.svg);
            right: 0;
            top: 0;
            width: 30px;
            height: 30px;
            transform: rotate(180deg);
            cursor: pointer;
        }


        &:last-child {
            border: 0;
            margin: 0;
            padding: 0;
        }

        .left {
            width: 100px;

            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        .right {
            flex: 1;
            margin: 0 0 0 15px;

            h4 {
                font-size: 16px;
            }

            p {
                margin: 0;
                font-size: 12px;
            }
        }
    }
}


span.filltik {
    color: #000;
}

.important {
    span {
        margin: 0 0 10px;
        display: block;
        color: #808080;
    }
}

.developer-list {
    height: 690px;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 4px;
}


::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.property-table-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px;
    overflow: hidden;

    @media (max-width:1440px) {
        width: 250px;
    }

    @media (max-width:1200px) {
        width: 200px;
    }
}

.all-property-details {
    padding: 50px 0;
}

.rooms {
    .primery-btn {
        width: auto;

        &:hover {
            background: #00594C;
            color: #fff;
        }

        img {
            margin: 0 5px 0 0;
        }
    }

    .table-icon {
        img {
            margin: 0 8px;
            cursor: pointer;
            width: 22px;
        }
    }
}

.filltik {
    font-size: 18px;
    color: #000000;
    display: flex;
    align-items: center;

    span {
        color: #045C46;
        font-size: 16px;
        border: 1px solid #045C46;
        border-radius: 50%;
        padding: 2px;
        min-width: 30px;
        min-height: 30px;
        margin: 0 0 0 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.advertise {
    padding: 20px 0;

    table {
        thead {
            tr {
                th {
                    font-size: 14px;
                }

                td {
                    font-size: 14px;
                }
            }
        }
    }

    .property {
        position: absolute;
        right: 0;
        width: 700px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        span {
            padding: 15px;
            border-radius: 10px;

            p {
                margin: 0;
                line-height: normal;
                font-size: 10px;
            }

            b {
                font-size: 24px;
            }
        }
    }
}

.t-l {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.t-banner {
    width: 150px;
    height: 40px;
    object-fit: cover;
    border-radius: 10px;
}

.view-on-map {
    position: absolute;
    right: 0;

    a {
        color: #DE7D38;
    }
}

.tab-box {
    .nav-tabs {
        background: #fff;
        padding: 5px;
        max-width: 610px;

        li {
            background: #FAFAFA;

            button {
                border: 0;
                color: #8F8F8F;
                font-size: 16px;
                min-width: 200px;
                font-weight: bold;

                &.active {
                    background: #E4ECEB;
                }
            }
        }
    }
}

.active-plan {

    select,
    input {
        width: 100%;
        background: #F3F4F6;
        border: 0;
        padding: 9px;
        border-radius: 10px;
        min-width: 300px;

        &:focus {
            outline: none;
        }
    }

    input {
        min-width: 100px;
        max-width: 160px;
    }
}

.empty-l {
    // background-color: grey;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;

    img {
        border-radius: 50%;
        object-fit: cover;
    }
}

.empty-b {
    width: 150px;
    height: 40px;
    background-color: grey;
    border-radius: 10px;
}


.nt-btn {
    background: #E4ECEB;
    border: 0;
    color: #2A9D4A;
    font-size: 12px;
    margin: 0 0 0 10px;

    &:disabled {
        opacity: 0.4;
        background: #E4ECEB;
        color: #2A9D4A;
        cursor: not-allowed;
    }

    &:hover {
        background: #d3d8d7;
        color: #2A9D4A;
    }
}

.reapt-2 {
    grid-template-columns: repeat(2, 1fr);
}
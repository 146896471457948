.video-library {
    padding: 40px 0;
}

.upload-title {
    justify-content: space-between;
    align-items: center;

    .backlink {
        a {
            display: flex;
            align-items: flex-start;
            font-size: 20px;
            color: #000;
            text-decoration: none;
        }
    }

    .search-video {
        input {
            background-color: #F3F4F6;
            border-radius: 10px !important;
            padding: 10px;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .search-video {
        a {
            background: #00594C;
            color: #fff;
            padding: 15px;
            display: flex;
            align-items: center;
            font-size: 18px;
            border-radius: 20px;
            text-decoration: none;

            img {
                margin: 0 5px 0 0;
            }
        }
    }
}

.video {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(5, 1fr);
    margin: 40px 0 0;

    .content {
        margin: 0 0 10px;
        position: relative;

        .vide-box {
            height: 160px;
            background: #F3F4F6;
            border-radius: 20px;
            padding: 0px;
            position: relative;
            display: block;
            object-fit: cover;

            .videoshow {
                height: 100%;
                width: 100%;
                position: relative;

                img {
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 20px;
                }

                video {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    position: relative;
                }

                img.playicon {
                    position: absolute;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    left: 0;
                    margin: 0 auto;
                    top: 50%;
                    cursor: pointer;
                    transform: translatey(-50%);
                    transition: all .5s;

                    &:hover {
                        transform: translatey(-50%) scale(1.2);
                    }
                }


            }

            .play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
            }
        }

        .dot {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }

        .video-title {
            background: #FFFFFF;
            padding: 10px;
            border-radius: 0 0 20px 20px;
            min-height: 60px;
            display: flex;
            align-items: center;

            h3 {
                font-size: 18px;
                color: #8F8F8F;
                margin: 0;
            }
        }
    }
}

.bs-popover-bottom {
    z-index: 0 !important;
}

.modal-backdrop {
    opacity: 0.8 !important;
}

.edit-delete {
    padding: 10px !important;
    min-width: 120px;
    box-shadow: 0px 2px 6px 0px #0000001A;
    border-width: 0 !important;

    span {
        display: block;
        padding: 0 0 10px;
        margin: 0 0 10px;
        border-bottom: 1px solid #ddd;

        &:last-child {
            border-bottom: 0;
            padding: 0px;
            margin: 0px;
        }

        &:hover {
            color: #00594C;
        }

        img {
            width: 25px;
            object-fit: contain;
            height: 24px;
            margin: 0 6px 0 0;
        }
    }
}

.search_icon {
    background-image: url(../../../public/assets/images/search.svg);
    background-size: 21px;
    background-position: 3% 50%;
    background-repeat: no-repeat;
    padding-left: 40px !important;
}

.video-thumb {
    width: 120px !important;
    position: relative;

    .cross {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
    }
}


.video-tag {
    position: relative;
    width: 130px;

    img {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        object-fit: cover;
    }

    img.cross-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 18px;
        height: 18px;
        object-fit: contain;
        border-radius: 0px;
        cursor: pointer;
    }
}

.playmusic {
    border-radius: 10px;
    min-height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 50px !important;
        height: 50px !important;
        margin: 0 auto;
        object-fit: contain !important;
        display: table;
    }
}
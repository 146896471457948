.header {
    padding: 20px 0;
    background-color: #fff;

    .container {
        justify-content: space-between;
        align-items: center;
    }

    .logout-btn {

        img {
            margin: 0 0 0 5px;
        }

        button {
            background-color: #E4ECEB;
            display: inline-block;
            padding: 10px;
            border-radius: 20px;
            min-width: 150px;
            text-align: center;
            text-decoration: none;
            color: #29061A;
            text-transform: capitalize;
            border: 0;

            img {
                margin: 0 5px 0 0;
            }
        }

        .builder {
            margin: 0 50px 0 0;
        }

        .builder,
        .rooms {
            a {
                color: #999;
                font-size: 20px;
                text-decoration: none;
                font-weight: 600;
                margin: 0 30px 0 0;

                &.active {
                    color: #2A9D4A;
                }
            }
        }
    }
}